import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';

import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CalendarModule } from 'primeng/calendar';
import { AnsiPipe } from '../../pipes/ansi.pipe';
import { ArchitecturesPipe } from '../../pipes/architectures.pipe';
import { Base64Pipe } from '../../pipes/base64.pipe';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';
import { ConvertBytesPipe } from '../../pipes/convertBytes.pipe';
import { ErrorsPipe } from '../../pipes/errors.pipe';
import { HighlighterPipe } from '../../pipes/highlighter.pipe';

import { LicensePipe } from '../../pipes/license.pipe';
import { PasswordPipe } from '../../pipes/password.pipe';
import { PersistPipe } from '../../pipes/persist.pipe';
import { ReducePipe } from '../../pipes/reduce.pipe';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { StatusCardPipe } from '../../pipes/status-card.pipe';
import { EditUserComponent } from '../users/components/edit-user/edit-user.component';
import { ButtonDemoComponent } from './components/button-demo/button-demo.component';
import { ButtonMenuOptionsComponent } from './components/button-menu-options/button-menu-options.component';
import { ConfigLoadComponent } from './components/config-actions/components/config-load/config-load.component';
import { ConfigActionsComponent } from './components/config-actions/config-actions.component';
import { DragDirective } from './components/custom-config/dragDrop.directive';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { FilterSelectViewNodeComponent } from './components/filter-select-view/filter-select-view-node.component';
import { FilterSelectViewComponent } from './components/filter-select-view/filter-select-view.component';
import { InputFileNewComponent } from './components/input-file-new/input-file-new.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { LinkReadmeComponent } from './components/link-readme/link-readme.component';
import { LoadingFileAnimationComponent } from './components/loading-file-animation/loading-file-animation.component';
import { ModalContactSalesComponent } from './components/modal-contact-sales/modal-contact-sales.component';
import { ModalDetailsComponent } from './components/modal-details/modal-details.component';
import { ModalErrorNewComponent } from './components/modal-error-new/modal-error-new.component';
import { ModalErrorTierPermissionComponent } from './components/modal-error-tier-permission/modal-error-tier-permission.component';
import { ModalIconTitleChipCloseComponent } from './components/modal-header/components/modal-icon-title-chip-close/modal-icon-title-chip-close.component';
import { ModalImgCropperComponent } from './components/modal-img-cropper/modal-img-cropper.component';
import { ModalImgComponent } from './components/modal-img/modal-img.component';
import { ModalImportCredentialsSecretsComponent } from './components/modal-import-credentials-secrets/modal-import-credentials-secrets.component';
import { ModalMainActionsComponent } from './components/modal-main-actions/modal-main-actions.component';
import { ModalTitleSubtitleComponent } from './components/modal-title-subtitle/modal-title-subtitle.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TableInfiniteComponent } from './components/table-infinite/table-infinite.component';

import { TableComponent } from './components/table/table.component';
import { AutofocusDirective } from './validators/autofocus.directives';
import { MenuActionsComponent } from './components/menu-actions/menu-actions.component';
import { CustomConfigComponent } from './components/custom-config/custom-config.component';
import { ModalSaveJsonComponent } from '../devices/device-detail/components/edit-json-config/components/modal-save-json/modal-save-json.component';
import { EditJsonConfigComponent } from '../devices/device-detail/components/edit-json-config/edit-json-config.component';

export const MY_MAT_TOOLTIP_DEFAULT_OPTIONS: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 100,
  touchendHideDelay: 100,
  disableTooltipInteractivity: true
};

@NgModule({
  declarations: [
    DragDirective,
    AnsiPipe,
    HighlighterPipe,
    PersistPipe,
    PasswordPipe,
    CapitalizePipe,
    ConvertBytesPipe,
    LicensePipe,
    ReplacePipe,
    SafePipe,
    ArchitecturesPipe,
    ReducePipe,
    StatusCardPipe,
    Base64Pipe,
    AutofocusDirective,
    FilterSelectViewComponent,
    ErrorsPipe,
    EditUserComponent,
    FilterSelectViewNodeComponent,
    TableComponent,
    TableInfiniteComponent,
    ModalImgComponent,
    ModalImgCropperComponent,
    InputFileComponent,
    DateTimePickerComponent,
    InputFileComponent,
    ModalImportCredentialsSecretsComponent,
    ConfigActionsComponent,
    ConfigLoadComponent,
    ModalErrorNewComponent,
    ModalDetailsComponent,
    ButtonMenuOptionsComponent,
    ModalErrorTierPermissionComponent,
    ModalContactSalesComponent,
    ModalTitleSubtitleComponent,
    StepperComponent,
    ButtonDemoComponent,
    InputFileNewComponent,
    ModalMainActionsComponent,
    LoadingFileAnimationComponent,
    LinkReadmeComponent,
    ModalIconTitleChipCloseComponent,
    CustomConfigComponent,
    ModalSaveJsonComponent,
    EditJsonConfigComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    CalendarModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSliderModule,
    ImageCropperModule,
    MatDatepickerModule,
    MatOptionModule,
    MatSelectModule,
    NgJsonEditorModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    NgOptimizedImage,
    MenuActionsComponent
  ],
  exports: [
    DragDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    AutofocusDirective,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSliderModule,
    ImageCropperModule,
    MatDatepickerModule,
    AnsiPipe,
    HighlighterPipe,
    PersistPipe,
    PasswordPipe,
    CapitalizePipe,
    ConvertBytesPipe,
    LicensePipe,
    ReducePipe,
    ReplacePipe,
    SafePipe,
    ArchitecturesPipe,
    StatusCardPipe,
    Base64Pipe,
    ErrorsPipe,
    EditUserComponent,
    FilterSelectViewNodeComponent,
    TableComponent,
    TableInfiniteComponent,
    ModalImgComponent,
    ModalImgCropperComponent,
    FilterSelectViewComponent,
    InputFileComponent,
    DateTimePickerComponent,
    InputFileComponent,
    ModalImportCredentialsSecretsComponent,
    ConfigActionsComponent,
    ConfigLoadComponent,
    ModalErrorNewComponent,
    ModalDetailsComponent,
    ButtonMenuOptionsComponent,
    ModalErrorTierPermissionComponent,
    ModalContactSalesComponent,
    ModalTitleSubtitleComponent,
    StepperComponent,
    ButtonDemoComponent,
    InputFileNewComponent,
    ModalMainActionsComponent,
    LoadingFileAnimationComponent,
    LinkReadmeComponent,
    ModalIconTitleChipCloseComponent,
    CustomConfigComponent,
    ModalSaveJsonComponent,
    EditJsonConfigComponent
  ]
})
export class SharedModule {}
