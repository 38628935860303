import { Injectable } from '@angular/core';
import { DeviceModel } from '../../common/models/common.model';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { UtilsService } from '../../common/services/utils.service';
import { EAgentCapabilities } from '../models/device-detail.model';
import { ActionsDeviceService } from './actions-device.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  device: DeviceModel;
  EAgentCapabilities = EAgentCapabilities;
  arrayPriorities: any[] = [];
  arraySelectBatch: { color: string; name: string; disabled: boolean; _id: string }[] = [];
  hadGeneral;
  hadConfig;
  hadNetwork;
  showConfig = false;
  configErrorMessage: any;
  hadAnalytics;
  hadTelemetry;
  hadCredentials;
  hadSecrets;
  hadVolumes;
  hadMlops;
  hadAppDocker;
  options: { color: string; name: string; disabled: boolean; _id: string }[] = [];

  constructor(
    private actionsDeviceService: ActionsDeviceService,
    private snackMessageService: SnackMessageService,
    private utilsService: UtilsService
  ) {}

  prepareMenuButtonNewCard(
    data: any,
    device: DeviceModel
  ): {
    color: string;
    name: string;
    disabled: boolean;
    _id: string;
  }[] {
    this.arrayPriorities = data;
    this.device = device;
    this.initializeAppPriorities();
    this.initialize();
    //console.log(data);
    return this.options;
  }

  initializeAppPriorities(): void {
    this.hadGeneral = this.findAppPriorityByType('info');
    this.hadConfig = this.findAppPriorityByType('config');
    this.hadNetwork = this.findAppPriorityByType('network');
    this.hadAnalytics = this.findAppPriorityByType('analytics');
    this.hadTelemetry = this.findAppPriorityByType('telemetry');
    this.hadCredentials = this.findAppPriorityByType('credentials');
    this.hadSecrets = this.findAppPriorityByType('secrets');
    this.hadVolumes = this.findAppPriorityByType('volumes');
    this.hadMlops = false;
    this.hadAppDocker = false;
  }

  findAppPriorityByType(type: string): any {
    return this.arrayPriorities.find((app) => app.type === type);
  }

  initialize(): void {
    this.initializeArraySelectBatch();
    this.initializeOptions();
  }

  initializeArraySelectBatch(): void {
    const commonBatch = [
      { color: 'docker', name: 'Docker app', disabled: this.hadAppDocker, _id: 'docker' },
      { color: 'marketplace', name: 'Marketplace app', disabled: false, _id: 'marketplace' },
      { color: 'generic', name: 'General info', disabled: !this.hadGeneral.hidden, _id: 'info' },
      { color: 'generic', name: 'Global config', disabled: !this.hadConfig.hidden, _id: 'config' },
      { color: 'generic', name: 'Networking', disabled: !this.hadNetwork.hidden, _id: 'network' },
      {
        color: 'generic',
        name: 'Analytics',
        disabled: !this.hadAnalytics.hidden,
        _id: 'analytics'
      },
      {
        color: 'generic',
        name: 'Telemetry',
        disabled: !this.hadTelemetry.hidden,
        _id: 'telemetry'
      },
      {
        color: 'generic',
        name: 'Docker credentials',
        disabled: !this.hadCredentials.hidden,
        _id: 'credentials'
      },
      {
        color: 'generic',
        name: 'Global secrets',
        disabled: !this.hadSecrets.hidden,
        _id: 'secrets'
      }
    ];
    this.arraySelectBatch = [];
    this.arraySelectBatch = [...commonBatch];
    if (this.device.agentCapabilities.includes(this.EAgentCapabilities.VOLUMES)) {
      this.arraySelectBatch.push({
        color: 'generic',
        name: 'Docker volumes',
        disabled: !this.hadVolumes.hidden,
        _id: 'volumes'
      });
    }
    if (this.device.agentCapabilities.includes(this.EAgentCapabilities.NEWDOCKER)) {
      this.arraySelectBatch.push({
        color: 'model',
        name: 'Model',
        disabled: false,
        _id: 'model'
      });
      //this.arraySelectBatch.splice(2, 0, { name: 'Model', disabled: false, _id: 'ia-model' });
    }
    this.arraySelectBatch = this.orderCards(this.arraySelectBatch);
    // const idx = this.arraySelectBatch.findIndex((item) => item.name === 'User Docker App');
    // this.arraySelectBatch.splice(idx + 1, 0, { name: 'divider', show: false, _id: 'divider' });
  }

  initializeOptions(): void {
    this.options = this.arraySelectBatch;
  }

  orderCards(data) {
    data.sort((a, b) => {
      // Definir el orden de las categorías
      const categoryOrder = ['marketplace', 'docker', 'model'];

      // Obtener el índice de la categoría en el orden definido
      const categoryIndexA = categoryOrder.indexOf(a._id);
      const categoryIndexB = categoryOrder.indexOf(b._id);

      // Comparar por categoría
      if (categoryIndexA !== -1 && categoryIndexB !== -1) {
        return categoryIndexA - categoryIndexB;
      }

      // Si alguno de los elementos no está en la lista de categorías, se colocará al final
      if (categoryIndexA === -1 && categoryIndexB === -1) {
        // Comparar por "show" con prioridad a false
        if (a.disabled === false && b.disabled === true) {
          return -1;
        } else if (a.disabled === true && b.disabled === false) {
          return 1;
        }

        // Si ambos tienen el mismo valor en "disabled" o ambos son true, comparar alfabéticamente por el nombre
        return a.name.localeCompare(b.name);
      } else if (categoryIndexA === -1) {
        return 1; // Mover elementos no encontrados en la lista de categorías hacia el final
      } else {
        return -1; // Mover elementos no encontrados en la lista de categorías hacia el final
      }
    });
    return data;
  }

  selectSendConfig(data: any, appConfig, id?: string): void {
    switch (data.type) {
      case 'global':
        this.sendAppConfigGlobal(appConfig, data);
        break;
      case 'docker':
      case 'marketplace':
      case 'model':
        this.sendAppConfigDocker(appConfig, data);
        break;
    }
    this.utilsService.setCacheExpirationInSeconds(1);
  }

  sendAppConfigGlobal(appConfig, data): void {
    let dataAppConfig = {
      appConfigId: data.appConfig?._id,
      appConfig: this.utilsService.conversorBase64(JSON.stringify(appConfig))
    };
    this.actionsDeviceService
      .sendAppConfigToDevice(data.device._id, dataAppConfig)
      .then(() => {
        this.configErrorMessage = null;
        this.showConfig = Object.keys(appConfig).length > 0;
      })
      .catch((err) => {
        this.configErrorMessage = 'Oops, could not get the apps info.';
        this.snackMessageService.readError(err);
      });
  }

  sendAppConfigDocker(appConfig, data): void {
    let dataAppConfig = {
      appConfigId: data.appConfig?._id,
      appConfig: this.utilsService.conversorBase64(JSON.stringify(appConfig))
    };
    const _appConfig = this.utilsService.conversorBase64(JSON.stringify(appConfig));
    this.actionsDeviceService
      .sendAppConfigDocker(data.device._id, data.spaceId, dataAppConfig)
      .subscribe({
        next: () => {
          this.configErrorMessage = null;
          this.showConfig = Object.keys(appConfig).length > 0;
        },
        error: (error) => {
          this.configErrorMessage = 'Oops, could not get the apps info.';
          this.snackMessageService.readError(error);
        }
      });
  }

  sendAppConfigApp(appConfig, data): void {
    this.actionsDeviceService
      .sendAppConfigApp(data.device._id, JSON.stringify(appConfig))
      .then(() => {
        this.configErrorMessage = null;
        // this.getAppConfig();
        this.showConfig = Object.keys(appConfig.config).length > 0;
      })
      .catch((err) => {
        this.configErrorMessage = 'Oops, could not get the apps info.';
        this.snackMessageService.readError(err);
      });
  }

  notSpecialApp(dataSpace) {
    switch (dataSpace?.current.applicationId.name) {
      case 'Solo provisioned apps':
      case 'Solo deployed apps':
      case 'Solo node list':
        return false;
      default:
        return true;
    }
  }
}
