<button   [matMenuTriggerFor]="menuToggler" (click)="showOptions()"   mat-icon-button>
  <span

    class="material-symbols-outlined menu-vert">
    more_vert
  </span>
</button>
  <mat-menu  #menuToggler="matMenu">
    @for (data of menuOptions; track data.action) {
      <menu [id]="data.action"  class="{{ className }}__menu-toggle-item" [innerHTML]="data.element" mat-menu-item>
      </menu>
    }
  </mat-menu>
