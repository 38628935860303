import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { commonLabels } from '../../../../common.labels';
import { CommonLabels, ConfigModel } from '../../../../models/common.model';
import { ActionsDeviceService } from '../../../../../devices/services/actions-device.service';
import { SnackMessageService } from '../../../../services/snackMessage.service';
import { ActionsAppConfigService } from '../../../../../app-config/services/actions-app-config.service';
import { ModalConfirmConfigurableComponent } from '../../../modal-confirm-configurable/modal-confirm-configurable.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'config-load',
  templateUrl: './config-load.component.html',
  styleUrls: ['./config-load.component.scss']
})
export class ConfigLoadComponent implements OnChanges, OnDestroy {
  @Input() modeBatch?: boolean;
  @Input() mode?: string = 'view';
  @Input() id: string;
  @Input() idConfigSelected: any;
  @Input() dataSelectConfiguration: Array<ConfigModel>;
  @Output() emitAction: EventEmitter<any> = new EventEmitter();
  actionsDeviceService = inject(ActionsDeviceService);
  actionsAppConfigService = inject(ActionsAppConfigService);
  snackMessageService = inject(SnackMessageService);
  matDialog = inject(MatDialog);
  className = 'config-load';
  config: any;
  labels: CommonLabels = commonLabels;
  selectedConfigId: string = '';
  messageError: string;
  selectName: any;

  ngOnChanges(): void {}
  deleteConfigId(id: string) {
    this.matDialog
      .open(ModalConfirmConfigurableComponent, {
        height: 'auto',
        width: '400px',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        disableClose: false,
        autoFocus: false,
        data: {
          titleMessage: 'Are you sure you want to delete this Config?',
          contentMessage: 'Once deleted, this action cannot be reversed.',
          button1Color: 'red',
          button1Message: 'Delete'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.result) {
          this.actionsDeviceService.deleteConfigId(id).subscribe({
            next: () => {
              this.actionsAppConfigService
                .getConfig(this.id === 'global-config' ? 'global' : 'spaces')
                .subscribe({
                  next: (response: ConfigModel[]) => {
                    this.dataSelectConfiguration = response;
                  },
                  error: (error) => {
                    console.log(error);
                    this.snackMessageService.readError(error);
                  }
                });
            },
            error: (error) => {
              this.snackMessageService.readError(error);
            }
          });
        }
      });
  }

  chooseActions({ type, config, action }) {
    this.deleteConfigId(config);
  }

  generateMenuOptions(): any {
    const options = [];

    options.push({
      element:
        '<span class="material-symbols-outlined menu-advanced">delete</span><span  class="text">Delete Config</span>',
      action: 'delete'
    });

    return options;
  }
  close() {
    this.emitAction.emit({
      event: '',
      action: 'close',
      selectedConfigId: this.idConfigSelected
    });
  }

  actionEmit(event, action): void {
    switch (action) {
      case 'load-config':
        this.emitAction.emit({
          event: event,
          action: 'load-config',
          selectedConfigId: this.idConfigSelected
        });
        break;
      case 'export-config':
        this.emitAction.emit({
          event: event,
          action: 'export-config',
          selectedConfigId: this.idConfigSelected
        });
        break;
    }
  }

  ngOnDestroy(): void {}

  protected readonly labelsCommon = commonLabels;
}
