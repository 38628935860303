<div class="{{ className }}__wrapper">
  <mat-dialog-content class="{{className}}__wrapper-container">
    <custom-config
      (emitAction)="getAtion($event)"
      (emitError)="hasJsonErrors($event)"
      (emitJson)="saveJson($event)"
      [dataSpace]="this.data.dataSpace"
      [appConfig]="data.appConfig"
      [device]="this.data.device"
      [appName]="data.appName"
      [id]="id"
      [modeBatch]="data.modeBatch"
      [mode]="mode"
      [optionsConfig]="optionsConfig"></custom-config>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
  <div
    class="{{ className }}__footer {{
      mode === 'view' ? 'custom-config' : 'custom-config__edit'
    }} {{ data.modeBatch ? 'batch' : '' }}">
    <div class="{{ className }}__footer-left" >
      <button
        (click)="onCancel()"
        class="{{ className }}__modal-button--primary-inverted big"
        color="dark-blue-inverted"
        id="{{ className }}-button-cancel"
        mat-dialog-close
        mat-flat-button>
        {{ labelsCommon.cancel }}
      </button>
    </div>
   <div class="{{ className }}__footer-right">
     <button
       (click)="sendAndSaveConfig()"
       [disabled]="disableSave"
       id="{{ className }}-button-add"
       color="light-primary-inverted big"
       mat-stroked-button>
       {{ labelsCommon.sendAndSave }}
     </button>
     <button
       (click)="sendConfig()"
       [disabled]="disableSave"
       class="{{ className }}__modal-button--primary big"
       color="dark-blue"
       id="{{ className }}-button-add"
       mat-flat-button>
       {{ labelsCommon.send }}
     </button>
   </div>
  </div>
  </mat-dialog-actions>
</div>