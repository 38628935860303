import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalConfirmConfigurableComponent } from '../../../../../../common/components/modal-confirm-configurable/modal-confirm-configurable.component';
import { ResizeWindowService } from '../../../../../../common/services/resize-window.service';

@Component({
  selector: 'app-modal-save-json',
  templateUrl: './modal-save-json.component.html',
  styleUrl: './modal-save-json.component.scss'
})
export class ModalSaveJsonComponent implements OnInit {
  className = 'modal-save-json';
  data = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<ModalConfirmConfigurableComponent>);
  name = '';
  resizeWindowService = inject(ResizeWindowService);
  defaultAppConfig = 'Global Config';

  ngOnInit() {
    this.name = this.generateName();
  }

  back(): void {
    this.dialogRef.close();
  }
  onConfirmed(): void {
    this.dialogRef.close({ result: true, data: this.name });
  }

  generateName(): string {
    let name = '';
    if (this.data.id !== 'global-config') {
      name = `${this.data.appName} Config`;
    } else {
      name = this.defaultAppConfig;
    }
    const dName = this.resizeWindowService.getPartsDate(new Date());
    let namePart1 =
      '' +
      dName[0] +
      (dName[1] + 1 < 10 ? '0' + (dName[1] + 1) : dName[1] + 1 > 12 ? dName[1] : dName[1] + 1) +
      (dName[2] < 10 ? '0' + dName[2] : dName[2]) +
      (dName[3] < 10 ? '0' + dName[3] : dName[3]) +
      (dName[4] < 10 ? '0' + dName[4] : dName[4]) +
      (dName[5] < 10 ? '0' + dName[5] : dName[5]);

    return namePart1 + ' - ' + name;
  }
}
