<div class="{{ className }}__dialog-content">
  <div class="{{ className }}__header">
    <modal-icon-title-chip-close
      (emitActions)="back()"
      [chip]="data.chipMessage | reduce: 6 : 7"
      [close]="true"
      [icon]="data.icon"
      [title]="data.titleMessage"></modal-icon-title-chip-close>
  </div>

  <mat-dialog-content>
    <div class="{{ className }}__form-field-wrapper">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input required [(ngModel)]="name" id="osVersionSignHash" matInput type="text" />
        @if (name === '') {
          <mat-error>Name is required</mat-error>
        }
      </mat-form-field>
    </div>
  </mat-dialog-content>
  @if (data.button1Color !== undefined && data.button2Color !== undefined) {
    <mat-dialog-actions align="end" class="{{ className }}__footer">
      <button
        (click)="back()"
        class="big"
        color="{{ data.button2Color }}"
        id="{{ className }}__button-{{
          data.button2Message
            | lowercase
            | replace: '_' : '-'
            | replace: ' ' : '-'
            | replace: '.' : '-'
        }}"
        mat-stroked-button>
        {{ data.button2Message }}
      </button>
      <button
        [disabled]="name===''"
        (click)="onConfirmed()"
        class="big"
        color="{{ data.button1Color }}"
        id="{{ className }}__button-{{
          data.button1Message
            | lowercase
            | replace: '_' : '-'
            | replace: ' ' : '-'
            | replace: '.' : '-'
        }}"
        mat-flat-button>
        {{ data.button1Message }}
      </button>
    </mat-dialog-actions>
  }
  @if (data.button1Color === undefined || data.button2Color === undefined) {
    <mat-dialog-actions align="end" class="{{ className }}__footer">
      <button
        (click)="back()"
        *ngIf="data.button2Color !== undefined"
        class="big"
        color="{{ data.button2Color }}"
        id="{{ className }}__button-{{
          data.button2Message
            | lowercase
            | replace: '_' : '-'
            | replace: ' ' : '-'
            | replace: '.' : '-'
        }}"
        mat-stroked-button>
        {{ data.button2Message }}
      </button>
      <button
        (click)="onConfirmed()"
        *ngIf="data.button1Color !== undefined"
        class="big"
        [disabled]="name===''"
        color="{{ data.button1Color }}"
        id="{{ className }}__button-{{
          data.button1Message
            | lowercase
            | replace: '_' : '-'
            | replace: ' ' : '-'
            | replace: '.' : '-'
        }}"
        mat-flat-button>
        {{ data.button1Message }}
      </button>
    </mat-dialog-actions>
  }
</div>
