import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // eslint-disable-next-line complexity
  constructor() {
    if (localStorage.getItem('userPermission')) {
      sessionStorage.setItem('userPermission', localStorage.getItem('userPermission'));
    }
    if (localStorage.getItem('devicePermission')) {
      sessionStorage.setItem('devicePermission', localStorage.getItem('devicePermission'));
    }
    if (localStorage.getItem('deplyPermission')) {
      sessionStorage.setItem('deplyPermission', localStorage.getItem('deplyPermission'));
    }
    if (localStorage.getItem('name')) {
      sessionStorage.setItem('name', localStorage.getItem('name'));
    }
    if (localStorage.getItem('email')) {
      sessionStorage.setItem('email', localStorage.getItem('email'));
    }
    if (localStorage.getItem('avatarUri')) {
      sessionStorage.setItem('avatarUri', localStorage.getItem('avatarUri'));
    }
    if (localStorage.getItem('isAdmin')) {
      sessionStorage.setItem('isAdmin', localStorage.getItem('isAdmin'));
    }
    if (localStorage.getItem('authToken')) {
      sessionStorage.setItem('authToken', localStorage.getItem('authToken'));
    }

    if (localStorage.getItem('deviceId')) {
      sessionStorage.setItem('deviceId', localStorage.getItem('deviceId'));
    }
  }

  public retrieveAuthToken = (): string => {
    return sessionStorage.getItem('authToken');
  };
  public storePersistentlyAuthToken = (token: string): void => {
    localStorage.setItem('authToken', token);
  };

  public storeAuthToken = (token: string): void => {
    sessionStorage.setItem('authToken', token);
  };

  public retrieveIsAdmin = (): boolean => {
    return sessionStorage.getItem('isAdmin') === 'true';
  };

  setUrlExpand(url: any) {
    localStorage.setItem('urlExpand', JSON.stringify(url));
  }

  setAppConfig(data: any) {
    localStorage.setItem('appConfig', JSON.stringify(data));
  }
  setUserList(data: any) {
    localStorage.setItem('userList', JSON.stringify(data));
  }
  getUserList() {
    return JSON.parse(localStorage.getItem('userList'));
  }
  removeAppConfig() {
    localStorage.removeItem('appConfig');
  }

  getAppConfig() {
    return localStorage.getItem('appConfig');
  }

  getUrlExpand() {
    return localStorage.getItem('urlExpand');
  }

  deleteUrlExpand() {
    return localStorage.removeItem('urlExpand');
  }

  setUrlReadmeExpand(url: any) {
    localStorage.setItem('urlReadmeExpand', JSON.stringify(url));
  }

  getUrlReadmeExpand() {
    return localStorage.getItem('urlReadmeExpand');
  }

  deleteUrlReadmeExpand() {
    return localStorage.removeItem('urlReadmeExpand');
  }

  public storeIsAdmin = (isAdmin: boolean): void => {
    localStorage.setItem('isAdmin', isAdmin.toString());
    sessionStorage.setItem('isAdmin', isAdmin.toString());
  };

  public retrieveAvatarUri = (): string => {
    return sessionStorage.getItem('avatarUri');
  };
  public getDeviceId = (): string => {
    return sessionStorage.getItem('deviceId');
  };
  public getDeviceName = (): string => {
    return localStorage.getItem('deviceName');
  };

  setDeviceId(mode: any) {
    localStorage.setItem('deviceId', mode.toString());
    sessionStorage.setItem('deviceId', mode.toString());
  }

  setPositionPhotoUpdate(mode: any) {
    localStorage.setItem('positionPhoto', mode.toString());
  }

  getPositionPhotoUpdate() {
    return localStorage.getItem('positionPhoto');
  }

  clearPositionPhotoUpdate() {
    localStorage.removeItem('positionPhoto');
  }

  setInputType(mode: any) {
    localStorage.setItem('typeInput', mode.toString());
  }

  clearInputType() {
    localStorage.removeItem('typeInput');
  }

  getInputType() {
    return localStorage.getItem('typeInput');
  }

  setSrcImage(src: string) {
    localStorage.setItem('srcImage', src);
  }

  getSrcImage() {
    return localStorage.getItem('srcImage');
  }

  clearSrcImage() {
    localStorage.removeItem('srcImage');
  }

  setDeviceName(mode: any) {
    localStorage.setItem('deviceName', mode.toString());
  }

  setGroupData(mode: any) {
    localStorage.setItem('group', JSON.stringify(mode));
  }

  getGroupData() {
    return localStorage.getItem('group');
  }

  public storeAvatarUri = (avatarUri: string): void => {
    localStorage.setItem('avatarUri', avatarUri);
    sessionStorage.setItem('avatarUri', avatarUri);
  };

  public retrieveEmail = (): string => {
    return sessionStorage.getItem('email');
  };

  public storeEmail = (email: string): void => {
    localStorage.setItem('email', email);
    sessionStorage.setItem('email', email);
  };

  public retrieveName = (): string => {
    return sessionStorage.getItem('name');
  };

  public storeName = (name: string): void => {
    localStorage.setItem('name', name);
    sessionStorage.setItem('name', name);
  };

  public retrieveDeplyPermission = (): boolean => {
    return sessionStorage.getItem('deplyPermission') === 'true';
  };

  public storeDeplyPermission = (deplyPermission: boolean): void => {
    localStorage.setItem('deplyPermission', deplyPermission.toString());
    sessionStorage.setItem('deplyPermission', deplyPermission.toString());
  };

  public setListColumn = (listColumn: any): void => {
    localStorage.setItem('listColumn', JSON.stringify(listColumn));
  };

  public getListColumn = (): string => {
    return localStorage.getItem('listColumn');
  };

  public retrieveDevicePermission = (): boolean => {
    return sessionStorage.getItem('devicePermission') === 'true';
  };

  public storeDevicePermission = (devicePermission: boolean): void => {
    localStorage.setItem('devicePermission', devicePermission.toString());
    sessionStorage.setItem('devicePermission', devicePermission.toString());
  };

  public retrieveUserPermission = (): boolean => {
    return sessionStorage.getItem('userPermission') === 'true';
  };

  setTabAnalytics(tab) {
    localStorage.setItem('tabAnalytics', tab);
  }

  getTabAnalytics() {
    return parseInt(localStorage.getItem('tabAnalytics'));
  }

  setAnaliticsRelative(data) {
    localStorage.setItem('dataRelativeAnalytics', JSON.stringify(data));
  }

  getAnaliticsRelative() {
    return JSON.parse(localStorage.getItem('dataRelativeAnalytics'));
  }

  clearAnaliticsRelative() {
    localStorage.removeItem('dataRelativeAnalytics');
  }

  setAnaliticsAbsolute(data) {
    localStorage.setItem('dataAbsoluteAnalytics', JSON.stringify(data));
  }

  getAnaliticsAbsolute() {
    return JSON.parse(localStorage.getItem('dataAbsoluteAnalytics'));
  }

  setApplicationId(id) {
    localStorage.setItem('applicationId', id);
  }

  getApplicationId() {
    return localStorage.getItem('applicationId');
  }

  public storeUserPermission = (userPermission: boolean): void => {
    localStorage.setItem('userPermission', userPermission.toString());
    sessionStorage.setItem('userPermission', userPermission.toString());
  };
  public removeListPagination = (view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.removeItem('listPagination' + view);
  };
  public retrieveListPagination = (view?: string): string => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.getItem('listPagination' + view);
  };
  public storeTotalListPagination = (total: number, view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    if (total !== undefined) {
      sessionStorage.setItem('total' + view, total.toString());
    }
  };
  public retrieveTotalListPagination = (view?: string): string => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.getItem('total' + view);
  };
  public removeTotalListPagination = (view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.removeItem('total' + view);
  };
  public storeListPagination = (pagination: string, view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    sessionStorage.setItem('listPagination' + view, pagination);
  };

  public retrieveListSorting = (view?: string): string => {
    if (view === undefined) {
      view = '';
    }
    return sessionStorage.getItem('listSorting' + view);
  };

  setGroup(group) {
    localStorage.setItem('group', JSON.stringify(group));
  }
  getGroup() {
    return JSON.parse(localStorage.getItem('group'));
  }
  setGroupName(name) {
    localStorage.setItem('groupName', name);
  }
  getGroupName() {
    return localStorage.getItem('groupName');
  }

  public storeListSorting = (sorting: string, view?: string): void => {
    if (view === undefined) {
      view = '';
    }
    sessionStorage.setItem('listSorting' + view, sorting);
  };

  public retrieveListSelection = (): string[] => {
    return JSON.parse(sessionStorage.getItem('listSelection')) || [];
  };

  public storeListSelection = (selection: string[]): void => {
    sessionStorage.setItem('listSelection', JSON.stringify(selection));
  };

  public retrieveDeviceKnown = (): boolean => {
    return JSON.parse(sessionStorage.getItem('deviceKnown')) === true;
  };

  public retrievePersistentlyDeviceKnown = (): boolean => {
    return JSON.parse(localStorage.getItem('deviceKnown')) === true;
  };

  public storePersistentlyDeviceKnown = (deviceKnown: boolean): void => {
    localStorage.setItem('deviceKnown', JSON.stringify(deviceKnown).toString());
  };

  public storeDeviceKnown = (deviceKnown: boolean): void => {
    sessionStorage.setItem('deviceKnown', JSON.stringify(deviceKnown).toString());
  };

  public retrieveQrImage = (): string => {
    return JSON.parse(sessionStorage.getItem('qrImage'));
  };

  public storeQrImage = (qrImage: string): void => {
    sessionStorage.setItem('qrImage', JSON.stringify(qrImage).toString());
  };

  public removeQrImage = (): void => {
    sessionStorage.removeItem('qrImage');
  };

  public removelocalStorageData = (): void => {
    localStorage.removeItem('userPermission');
    localStorage.removeItem('devicePermission');
    localStorage.removeItem('deplyPermission');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('avatarUri');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('userPermission');
    sessionStorage.removeItem('devicePermission');
    sessionStorage.removeItem('deplyPermission');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('avatarUri');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('listPagination');
    sessionStorage.removeItem('listSorting');
    sessionStorage.removeItem('listTags');
    sessionStorage.removeItem('listSelection');
    sessionStorage.removeItem('qrImage');
    sessionStorage.removeItem('deviceKnown');
  };
  public retrieveSelectedTab = (): string => {
    return localStorage.getItem('selectedTab');
  };
  public removeSelectedTab = (): void => {
    localStorage.removeItem('selectedTab');
  };
  public storeSelectedTab = (indexTab: number): void => {
    const index = indexTab.toString();
    localStorage.setItem('selectedTab', index);
  };

  setViewMode(mode: string, view?: string) {
    if (view === undefined) {
      view = '';
    }
    localStorage.setItem('viewMode' + view, mode.toString());
  }

  getViewMode(view?: string) {
    if (view === undefined) {
      view = '';
    }
    return localStorage.getItem('viewMode' + view);
  }
  setFilters(filters: any) {
    localStorage.setItem('filters', JSON.stringify(filters));
  }
  getFilters() {
    return JSON.parse(localStorage.getItem('filters'));
  }
  removeFilters() {
    localStorage.removeItem('filters');
  }

  setOpenedLogsList(list: any) {
    localStorage.setItem('openedLogsList', JSON.stringify(list));
  }

  removeItemFromOpenedLogsList(name) {
    let list = JSON.parse(this.getOpenedLogsList());
    let result;
    if (list !== null && list.length > 0) {
      result = list.filter((log) => {
        if (!log.name.includes(name)) {
          return log;
        }
      });
      if (result.length === 0) {
        localStorage.removeItem('openedLogsList');
      } else {
        localStorage.removeItem('openedLogsList');
        localStorage.setItem('openedLogsList', JSON.stringify(result));
      }
    }
  }

  getOpenedLogsList() {
    return localStorage.getItem('openedLogsList');
  }
}
