import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { commonLabels } from '../../../../common/common.labels';
import { ResizeWindowService } from '../../../../common/services/resize-window.service';
import { UploadModalLabels } from '../../../models/device-detail.model';
import { CardService } from '../../../services/card.service';
import { uploadModalLabels } from '../../devices-detail.labels';
import { ModalSaveJsonComponent } from './components/modal-save-json/modal-save-json.component';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { UtilsService } from '../../../../common/services/utils.service';
import { ActionsAppConfigService } from '../../../../app-config/services/actions-app-config.service';

@AutoUnsubscribe()
@Component({
  selector: 'edit-json.json-config',
  templateUrl: './edit-json-config.component.html',
  styleUrls: ['./edit-json-config.component.scss']
})
export class EditJsonConfigComponent implements OnDestroy, OnInit {
  @Output() emitError: EventEmitter<any> = new EventEmitter();
  @Output() emitJson: EventEmitter<any> = new EventEmitter();
  mode: string = 'edit';
  id: string;
  className: string = 'edit-json-config';
  labelsCommon = commonLabels;
  disableSave = false;
  action = '';
  matDialog = inject(MatDialog);
  showJsonArea = true;
  labels: UploadModalLabels = uploadModalLabels;
  dataSend: any;
  width: number = 0;
  messageError: string = '';
  fileName: string = '';
  json: any;
  optionsConfig = new JsonEditorOptions();
  matDialogRef = inject(MatDialogRef<EditJsonConfigComponent>);
  cardService = inject(CardService);
  resizeWindowService = inject(ResizeWindowService);
  snackMessageService = inject(SnackMessageService);
  utilsService = inject(UtilsService);
  actionsAppConfigService = inject(ActionsAppConfigService);
  data: {
    appConfig: any;
    type: string;
    modeBatch: boolean;
    dataSpace: any;
    device: any;
    spaceId?: any;
    id?: string;
    appName?: string;
  } = inject(MAT_DIALOG_DATA);

  constructor() {
    this.optionsConfig.mode = 'code';
    this.optionsConfig.modes = ['code', 'text', 'tree', 'view'];
    this.optionsConfig.statusBar = false;
    this.optionsConfig.enableSort = false;
    this.optionsConfig.search = true;
    this.optionsConfig.enableTransform = true;
    this.optionsConfig.navigationBar = false;
    this.data.appConfig.config = this.data.appConfig.config || {};
    this.id = this.data.id;
  }

  ngOnInit() {
    this.initSubscriptions();
    //console.log(this.data);
  }

  onCancel() {
    if (this.action !== '') {
      this.action = '';
    } else {
      // console.log('onCancel');
      this.matDialogRef.close();
    }
  }

  sendConfig() {
    this.sendAppConfigForm();
  }

  sendAndSaveConfig() {
    this.matDialog
      .open(ModalSaveJsonComponent, {
        height: 'auto',
        width: this.width > 700 ? '600px' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        disableClose: false,
        autoFocus: false,
        data: {
          width: this.width,
          icon: 'save',
          appName: this.data.appName,
          id: this.data.id,
          titleMessage: 'Save your new Config',
          button1Color: 'primary',
          button1Message: 'Save'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== undefined) {
          if (result.result) {
            this.saveConfig(result);
          }
        }
      });
  }

  public sendAppConfigForm(id?): void {
    let appConfig = '';
    // Json editor data prepare
    if (this.showJsonArea) {
      // const jsonData = this.configForm.controls.appConfig.value;
      if (!this.data.appConfig.config) {
        return;
      }
      console.log(this.data.appConfig.config);
      appConfig = this.data.appConfig.config;
      this.data.appConfig._id = id;
    }

    // From editor data prepare
    this.cardService.selectSendConfig(this.data, appConfig);
    this.matDialogRef.close({});
  }
  saveConfig(event) {
    const config: any = {
      name: event.data,
      isGlobal: this.id === 'global-config',
      description: '',
      appConfig: this.utilsService.conversorBase64(JSON.stringify(this.data.appConfig.config))
    };
    // this.data = this.appConfig.config;
    this.actionsAppConfigService.saveConfig(config).subscribe({
      next: (dataConfig) => {
        console.log('dataConfig', dataConfig);
        this.sendAppConfigForm(dataConfig._id);
      },
      error: (error) => {
        error.error.response.errorCode === 'E089'
          ? (this.messageError = 'Name already exists')
          : this.snackMessageService.readError(error);
      }
    });
  }

  initSubscriptions() {
    this.resizeWindowService.width$.subscribe({
      next: (value: number) => {
        this.width = value;
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.width = value;
          },
          700,
          'loadingConfig'
        );
      }
    });
  }

  getAtion(event) {
    this.action = event;
    //console.log('getAtion', event);
  }

  hasJsonErrors(event): void {
    this.disableSave = event;
    this.jsonSendBtnStatus();
  }

  jsonSendBtnStatus(): void {
    if (this.disableSave) {
      this.dataSend = this.json;
    } else {
      this.dataSend = this.json;
    }
  }

  saveJson(event: any): void {
    //console.log('saveJson', event);
    this.data.appConfig.config = event;
    //console.log('this.data.appConfig', this.data);
    this.json = event;
    this.jsonSendBtnStatus();
  }

  ngOnDestroy() {}
}
