import { Observable } from 'rxjs';
import { ConfigModel } from '../../common/models/common.model';
import { Injectable } from '@angular/core';
import { BackEndCommunicationService } from '../../common/services/beComm.service';

@Injectable({
  providedIn: 'root'
})
export class ActionsAppConfigService {
  constructor(private backEndCommunicationService: BackEndCommunicationService) {}

  getConfig(filter = 'all'): Observable<ConfigModel[]> {
    return this.backEndCommunicationService.getResourceObservable('/appconfigs?filter=' + filter);
  }

  saveConfig(data: ConfigModel): Observable<ConfigModel> {
    return this.backEndCommunicationService.postResourceObservable('/appconfigs', data);
  }

  getConfigById(configId: string): Observable<ConfigModel> {
    return this.backEndCommunicationService.getResourceObservable('/appconfigs/' + configId);
  }

  updateConfig(configId: string, data: ConfigModel): Observable<ConfigModel> {
    return this.backEndCommunicationService.putResourceObservable('/appconfigs/' + configId, data);
  }
}
