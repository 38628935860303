<div class="{{ className }}">
  <div class="{{ className }}__wrapper">
    <div class="{{ className }}__wrapper-form">
      <mat-radio-group aria-label="Select an option">
        @for (data of dataSelectConfiguration; track data) {
          <div
            [ngClass]="data._id === idConfigSelected ? 'selected' : ''"
            class="{{ className }}__list">
            <div class="{{ className }}__list-option">
              <div class="{{ className }}__list-option-check">
                <mat-radio-button
                  color="primary"
                  (click)="idConfigSelected = data._id"
                  [checked]="idConfigSelected === data._id"
                  [value]="data._id"></mat-radio-button>
              </div>
              <div>
                <p class="{{ className }}__list-option-name">{{ data.name }}</p>
                @if (data.spaces.length > 0 || data.devices.length > 0) {
                  <div class="{{ className }}__list-option-statusCheck">
                    <span class="material-symbols-outlined {{ className }}__icon-check" matPrefix>
                      check_small
                    </span>
                    @if (data.spaces.length > 0) {
                      <span class="{{className}}__list-option-subtitle">Applied on: {{ data.spaces.length }} apps</span>
                    }
                    @if (data.devices.length > 0) {
                      <span class="{{className}}__list-option-subtitle">Applied on: {{ data.devices.length }} device</span>
                    }
                  </div>
                } @else {
                  <div class="{{ className }}__list-option-status">
                    <span class="material-symbols-outlined {{ className }}__icon-check" matPrefix>
                      check_indeterminate_small
                    </span>
                    <span
                      class="{{className}}__list-option-subtitle"
                      >Not applied. Last:
                      {{
                        data.lastUpdated === '1970-01-01T00:00:00.000Z' ? 'None' : data.lastUpdated
                      }}</span
                    >
                  </div>
                }
              </div>
            </div>
            <div class="{{ className }}__list-option-buttons">
<!--              <button mat-icon-button (click)="deleteConfigId(data._id)">-->
<!--                <mat-icon class="material-icons-outlined">delete</mat-icon>-->
<!--              </button>-->
              <button (click)="actionEmit($event, 'export-config')" mat-icon-button>
                <mat-icon class="material-icons-outlined">file_download</mat-icon>
              </button>
              <menu-actions
                (emitAction)="chooseActions({type:'delete',config:data._id,action:'delete-config'})"
                [menuOptions]="generateMenuOptions()"
                [config]="{type:'delete',id:data._id}"></menu-actions>
            </div>
          </div>
        } @empty {
          <div class="{{ className }}__empty-container">
            <img
              class="{{ className }}__empty-img"
              [ngSrc]="'assets/images/detail-component/preview.svg'"
              height="62"
              width="62"
              alt="" />
            <h2 class="{{ className }}__empty-title">Your Config list is empty.</h2>
            <p class="{{ className }}__empty-subtitle">
              Once you save an existent config it will be stored in here
            </p>
          </div>
        }
      </mat-radio-group>
    </div>
  </div>
  <mat-dialog-actions
    align="end"
    class="{{ className }}__footer {{
      mode === 'view' ? 'custom-config' : 'custom-config__edit'
    }} {{ modeBatch ? 'batch' : '' }}">
    <button
      (click)="close()"
      class="{{ className }}__modal-button--primary-inverted big"
      color="dark-blue-inverted"
      id="{{ className }}__button-{{
        labels.cancel | lowercase | replace: ' ' : '-' | replace: '_' : '-' | replace: '.' : '-'
      }}"
      mat-flat-button>
      {{ labels.cancel }}
    </button>
    @if (dataSelectConfiguration.length > 0) {
      <button
        (click)="actionEmit($event, 'load-config')"
        class="onlyplaceholder big"
        color="dark-blue"
        id="{{ className }}__button-{{
          labels.load | lowercase | replace: ' ' : '-' | replace: '_' : '-' | replace: '.' : '-'
        }}"
        mat-flat-button>
        {{ labels.load }}
      </button>
    }
  </mat-dialog-actions>
</div>
